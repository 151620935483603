import React from 'react'
import GoogleIcon from './googleIcon'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { FaStar, FaStarHalf } from "react-icons/fa";
import StarRatings from 'react-star-ratings';

let Container = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  background: white;
  padding: 0.5rem;
  border-top: 5px solid #4fce6a;
  border-radius: 2px;
  z-index: 2147482999!important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26)!important;
  display: flex;
  flex-direction: row;
  align-items: center;
  .widget-text {
    position: inline-block;
  }
`

let GoogleWidget = ({}) => {
  const data = useStaticQuery(graphql`
    query GoogleQuery {
      googlePlacesPlace {
        name
        rating
        user_ratings_total
      }
    }
  `)
  let place = data.googlePlacesPlace
  return (
    <Container className="d-none d-lg-flex">
      <GoogleIcon className="mr-3"/>
      <div className="widget-text">
        Google Rating
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-2" style={{color: '#e7711b', fontSize: '1.25rem'}}>{place.rating}</span>
          <StarRatings
            rating={place.rating}
            starRatedColor="#f76900"
            starEmptyColor="#fff"
            numberOfStars={5}
            starDimension="20px"
            starSpacing="3px"
          />
        </div>
        <div className="small text-secondary">
          Based on {place.user_ratings_total} reviews.
        </div>
      </div>
    </Container>
  )
}

export default GoogleWidget
