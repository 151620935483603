import React from "react"
import {Container} from 'reactstrap'
import website from '../data/website'
import Link from './link'
import styled from 'styled-components'

let Subfooter = styled.div`
  background: #89969e;
  color: white;
  text-transform: uppercase;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
`

export default () => (
  <Subfooter>
    <Container className="d-flex py-3">
      <div className="ml-auto small">
        © {new Date().getFullYear()} {website.name} | Website by <Link to="https://www.dealerstudio.com.au">Dealer Studio</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </Container>
  </Subfooter>
)
