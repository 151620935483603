import React from "react"
import { useStaticQuery} from "gatsby"
import { Row, Col, Container } from 'reactstrap';
import Link from './link'
import website from '../data/website'
import styled from 'styled-components'
import bg from "../images/footer-bg.jpg"
import { graphql } from 'gatsby'
import Rsca from '../images/rsca'
import Clock from 'react-live-clock';
import SocialIcons from './socialIcons'

const Footer = styled.footer`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  h5 {
    margin-bottom: 1.5rem;
  }
  ul {
    -webkit-padding-start: 0;
    padding: 0;
  }
  ul > li {
    list-style: none;
  }
  ul > li > a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Menu = ({group}) => (
  <Col md={3} xs={12} className="mb-2">
    <h5 className="mb-5">{group.nodes[0].name.replace('(Footer)', '')}</h5>
    <ul>
      {group.nodes[0].items.map((link, i) => (
        <li key={i}>
          <Link to={link.url}>{link.title}</Link>
        </li>
      ))}
    </ul>
  </Col>
)

export default () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allWordpressWpApiMenusMenusItems(filter: {name: {in: ["Workshops (Footer)", "Mechanics (Footer)", "Job Seekers (Footer)"]}}, sort: {order: DESC, fields: name}) {
        group(field: name) {
          nodes {
            name
            items {
              title
              url
              wordpress_children {
                title
                url
                wordpress_children {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  let address = website.address
  return (
    <Footer className="footer">
      <Container className="py-5">
        <Row>
          <Col>
            <h5 className="mb-5">Contact Details</h5>
            <ul>
              <li className="my-2"><b className="mb-2">Phone:</b></li>
              {website.phone_numbers.map((p, i) =>
                <li key={i}>
                  <Link to={'tel:' + p}>{p}</Link>
                </li>
              )}
              <li className="my-2"><b>Address:</b></li>
              {`${address.street}, ${address.city}`} <br/>
              {`${address.state} ${address.postcode}, ${address.country}`}
            </ul>
            <div className="font-weight-bold">Sydney Local time:</div>
            <Clock format={'h:mm A'} ticking={true} timezone={'Australia/Sydney'} />
            <span> - </span>
            <Clock format={'DD/MM/YYYY'} ticking={true} timezone={'Australia/Sydney'} />
          </Col>
          {data.allWordpressWpApiMenusMenusItems.group.map((group, i) => (
            <Menu group={group} key={i}/>
          ))}
          <Col>
            <h5>Be the first to know. Like us for job alerts.</h5>
            <div className="mb-3">
              <SocialIcons/>
            </div>
            <h5>RSCA</h5>
            <Rsca style={{maxWidth: '300px'}}/>
            <p className="mt-3">Accredited Professional Recruiter</p>
          </Col>
        </Row>
      </Container>
    </Footer>
  )
}
