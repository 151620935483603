import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 109.08 12.27" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            d="M81.71 8.86V7.12h-1v.82H79V6.51h1.37v-.92H79V4.33h1.69v.81h1V3.41h-4.8v.92h.65v3.61h-.65v.92zm-8.28 0v-.92h-.58V6.45h1.49v1.49h-.55v.92h2.61v-.92h-.68V4.33h.68v-.92h-2.61v.92h.55v1.19h-1.49V4.33h.58v-.92h-2.64v.92h.69v3.61h-.69v.92zm-4.07 0v-.92h-.82V4.33h.84V5.6h.92V3.41h-4.94V5.6h.94V4.33h.82v3.61h-.82v.92zm-10.6 0v-.92h-.69V4.86l2.35 4h1.22V4.33h.76v-.92H60v.92h.71v2.48l-2-3.4h-2.34v.92h.73v3.61h-.73v.92zm-4.95-4a1.93 1.93 0 01.42 1.29 1.93 1.93 0 01-.44 1.36 1.38 1.38 0 01-1.06.47 1.36 1.36 0 01-1.07-.48 1.84 1.84 0 01-.44-1.28 2 2 0 01.44-1.38 1.38 1.38 0 011.07-.47 1.35 1.35 0 011.08.49m-3.31-.72a2.72 2.72 0 00-.8 2 2.79 2.79 0 00.78 2 2.91 2.91 0 002.25.83 3 3 0 002.21-.81 2.7 2.7 0 00.81-2 2.72 2.72 0 00-.8-2 3.43 3.43 0 00-4.45 0M109.08 8.76V7.05h-1v.8h-1.68v-1.4h1.33v-.91h-1.33V4.31h1.65v.79h1V3.41h-4.72v.9h.67v3.54h-.64v.91zm-7.31 0l1.54-4.48h.69v-.9h-2.37v.9h.68L101.37 7l-.9-2.68h.69v-.9h-2.73v.9h.64l1.59 4.48zm-5-4a1.89 1.89 0 01.42 1.27 1.9 1.9 0 01-.43 1.33 1.39 1.39 0 01-1 .47 1.36 1.36 0 01-1-.47 1.85 1.85 0 01-.42-1.27 2 2 0 01.42-1.35 1.38 1.38 0 011-.46 1.33 1.33 0 011.06.48m-3.24-.66a2.68 2.68 0 00-.79 2 2.7 2.7 0 00.68 1.9 2.84 2.84 0 002.2.82A2.91 2.91 0 0097.85 8a2.65 2.65 0 00.79-1.95 2.65 2.65 0 00-.79-1.95 3 3 0 00-2.18-.8 2.92 2.92 0 00-2.18.8m-6.44 4.66v-.91h-.7V4.31h.13l1.67 4.45h.58l1.66-4.45h.13v3.54h-.65v.91h2.69v-.91h-.69V4.31h.69v-.9h-2.73l-1.18 3.13-1.18-3.13h-2.68v.9h.69v3.54h-.69v.91z"
            fill="#8c99a1"
          />
          <path
            d="M43.53 8.51a2.19 2.19 0 001.3.42 1.77 1.77 0 001.28-.48 1.5 1.5 0 00.51-1.15 1.68 1.68 0 00-.21-.83 1.51 1.51 0 00-.24-.34 1.58 1.58 0 00-.28-.25 11.74 11.74 0 00-1.07-.58 4.37 4.37 0 01-.7-.38.26.26 0 01-.12-.24c0-.23.14-.34.4-.34a.81.81 0 01.79.64h1.21V3.43h-1.23v.34A1.85 1.85 0 0044 3.36a1.75 1.75 0 00-1.2.43A1.46 1.46 0 0042.27 5a1.46 1.46 0 00.21.77 1.67 1.67 0 00.5.55 7.07 7.07 0 001 .52 3.9 3.9 0 01.69.36.4.4 0 01.16.31c0 .23-.15.35-.44.35a.86.86 0 01-.83-.71h-1.25v1.71h1.22zm-5-1h-.35v-.84h1.22v.89h-.35v1.3h2.86v-1.3h-.41V4.73h.41v-1.3h-2.86v1.3h.35v.83h-1.26v-.83h.35v-1.3h-2.86v1.3H36v2.83h-.41v1.3h2.86zM33 7.66c-.61 0-.91-.51-.91-1.52s.29-1.52.88-1.52a.84.84 0 01.57.21 1.15 1.15 0 01.36.58h1.35v-2H34v.36a2.71 2.71 0 00-3.48.36 2.87 2.87 0 00-.73 2 2.81 2.81 0 00.75 2A3 3 0 0032.8 9a2.71 2.71 0 001.8-.56A2.5 2.5 0 0035.42 7L34 6.47c-.14.79-.47 1.19-1 1.19m-3.47-.54h-1.42v.44h-1.24v-.89H28v-1.1h-1.13v-.84h1.24v.44h1.42V3.43h-5.18v1.3h.42v2.83h-.42v1.3h5.18zm-6.69.44h-.48V4.73H23v1h1v-2.3h-5.38v2.29h1v-1h.62v2.84h-.47v1.3h3.05z"
            fill="#375896"
          />
          <path
            d="M8.08 10.52a5.52 5.52 0 01-1.79-1.71l-1.5.4a5 5 0 003.29 1.31m2.56-2.88L9.21 8l.51 2a4.31 4.31 0 00.92-2.32M7.28.85L7.87 3l1.52-.41A4.53 4.53 0 007.28.85M3.34 4.24l1.54-.41a5.41 5.41 0 01.64-2.52 5 5 0 00-2.18 2.93m1.84 2a8.38 8.38 0 01-.3-2l-1.57.43h-.06a5.14 5.14 0 00.06 2.1zm5.28-1l-1.88.5.53 2 1.56-.41a7.6 7.6 0 00-.21-2.07M5.6 4a8.05 8.05 0 00.28 2l1.91-.51-.53-2zm0-.38l1.56-.41L6.58 1a4.4 4.4 0 00-1 2.61m4-.69L8 3.38l.53 2 1.88-.5a7.72 7.72 0 00-.78-1.9M8.41 7.86l-.53-2L6 6.39a7.69 7.69 0 00.85 1.9zM9 10.17l-.53-2L7 8.61a4.41 4.41 0 002 1.56M4.5 8.93h.07l1.52-.41a8.46 8.46 0 01-.82-1.9l-1.86.5A5 5 0 004.5 8.93M6.67 0h.23c.12 0 3 .42 4.19 4.71a6.55 6.55 0 01-.39 5.11A5 5 0 0013 4.25a.36.36 0 11.7-.19 5.71 5.71 0 11-7-4"
            fill="#a7b6bf"
          />
          <path
            d="M13.3 2.81a.46.46 0 01-.33.56.45.45 0 01-.55-.32.44.44 0 01.32-.56.46.46 0 01.56.32M12.48 1.77a.39.39 0 11-.48-.28.4.4 0 01.48.28M11.38.94a.3.3 0 01-.2.36.29.29 0 01-.36-.21.29.29 0 01.56-.15"
            fill="#a7b6bf"
          />
          <path
            d="M11.45 3a.91.91 0 10-.9.91.9.9 0 00.9-.91M13 6.85a.07.07 0 01-.06 0 .09.09 0 01-.07 0 .12.12 0 01-.05 0l-.35-1a.09.09 0 010-.07.07.07 0 01.05 0h.08l.36 1a.16.16 0 010 .07m.9.58v-.06a.41.41 0 00-.05-.09.6.6 0 00-.33-.35.57.57 0 00-.25-.06 4.32 4.32 0 01-.23-.42.39.39 0 00.12-.35.42.42 0 00-.38-.34l-.17-.54a.33.33 0 00.07-.13.6.6 0 00.05-.3.67.67 0 00-.05-.19.58.58 0 00-.22-.32.7.7 0 00-.67-.14l.32.27a.37.37 0 01.09.2.29.29 0 01-.07.18.29.29 0 01-.22.11.27.27 0 01-.23-.08l-.29-.25a.53.53 0 000 .28v.16a.63.63 0 00.34.34.58.58 0 00.26.06l.15.35-1.21.24s-.72-1.33-.89-1.56a1 1 0 00-.88-.53H6.66l-1.29 1.8a.41.41 0 00.09.58.43.43 0 00.24.08.42.42 0 00.3-.17l1-1.46h1.36L5.57 9.22H3.45a.57.57 0 000 1.14h2.73L7.65 8.1l1.84.9.51 2.54a.57.57 0 00.56.45h.12a.57.57 0 00.43-.68l-.65-3.09-1.85-.92 1.06-1.78.77 1.41 2-.3.16.49a.75.75 0 00-.07.12.63.63 0 000 .3.65.65 0 000 .2.58.58 0 00.23.32.46.46 0 00.16.11.62.62 0 00.5 0l-.33-.28a.37.37 0 01-.09-.2.25.25 0 01.08-.18.27.27 0 01.22-.11.29.29 0 01.23.07l.29.25a.5.5 0 000-.27"
            fill="#375896"
          />
          <path fill="#375896" d="M0 11.89h16.8v.38H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
