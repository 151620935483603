import React, { useState, forwardRef, useImperativeHandle } from "react"
import styled from 'styled-components'
import SideMenuInner from './sideMenuInner'
import socialIcons from '../data/socialLinks'
import SocialIcon from './socialIcon'

let OuterContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  transition: all 0.5s ease 0s;
  z-index: 1000;
  visibility: hidden;
  &.active {
    background: rgba(0, 0, 0, 0.3);
    visibility: unset;
    position: fixed;
  }
`

let SideMenu = styled.div`
  position: fixed;
  right: -300px;
  z-index: 1100;
  width: 300px;
  height: 100%;
  transition: all 0.5s ease 0s;
  background: white;
  &.active {
    right: 0;
  }
`

export default forwardRef((props, ref) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    toggle() {
      setSideMenuOpen(prevState => !prevState);
    }
  }));

  const toggle = (e) => {
    if (e.target === e.currentTarget) {
      setSideMenuOpen(prevState => !prevState)
    }
  }

  return (
    <OuterContainer onClick={toggle} className={sideMenuOpen ? 'active' : ''}>
      <SideMenu className={sideMenuOpen ? 'active' : ''}>
        <SideMenuInner/>
        <div className="px-3">
          {socialIcons.map((icon, i) => (
            <SocialIcon
              icon={icon}
              key={i}
              className="mr-2"
            />
          ))}
        </div>
      </SideMenu>
    </OuterContainer>
  )
});
