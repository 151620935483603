import React from 'react';
import {Link as GatsbyLink} from "gatsby"

export default ({ children, to, activeClassName, target = "_blank", ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  const phone = /^tel:.*$/.test(to);
  const email = /^mailto:.*$/.test(to);
  const url = 'https://www.techsonthemove.com.au'
  const api = 'https://app-au.techsonthemove.com'
  const api2 = 'http://159.65.10.85/'

  const totm = to && to.startsWith(url)
  const wp_api = to && to.startsWith(api)
  const wp_api2 = to && to.startsWith(api2)

  const ukurl = 'https://www.techsonthemove.co.uk'
  const ukapi = 'https://app-uk.techsonthemove.com'
  const totmuk = to && to.startsWith(ukapi)

  // Use gatsby-link for internal links, and <a> for others
  if (phone) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }
  if (email) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }
  if (internal || totm || wp_api || wp_api2 || totmuk) {
    let processed_to = to.replace(url, '').replace(api, '').replace(api2, '').replace(ukapi, '').replace(ukurl, '')
    let attrs = {}
    if (activeClassName) attrs.activeClassName = activeClassName
    return (
      <GatsbyLink to={processed_to} {...attrs} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} target={target} rel="noopener noreferrer" {...other}>
      {children}
    </a>
  );
};
