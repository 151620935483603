import React, { useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Container } from 'reactstrap';
import Link from './link'
import Logo from '../images/logo.js'
import LogoHorizontal from '../images/logo-horizontal.js'
import withMenu from './withMenu'
import { FaBars, FaCaretRight } from "react-icons/fa";
import styled from 'styled-components'
import Sticky from 'react-stickynode';

let DropdownSubmenu = styled.li`
  position: relative;
  transition: all 0.5s ease 0s;
  & > ul {
    border: none;
    border-radius: 0;
    transition: all 0.5s ease 0s;
    position: absolute;
    background: #929fa7;
    top: 0;
    right: 100%;
    margin-top: 0px;
    margin-left: 0px;
    padding: 0.25rem 0;
    visibility: hidden;
    max-height: calc(100vh - 200px);
    overflow: scroll;
  }
  &:hover {
    & > ul {
      visibility: visible;
    }
  }
`

let StyledSticky = styled(props => <Sticky {...props}/>)`
  .header {
    background: white;
  }
  svg.logo-horizontal {
    height: 20px !important;
  }
`

let StyledDropdownMenu = styled(props => <DropdownMenu {...props}/>)`
  background: #929fa7;
  border-radius: 0;
  border: none;
  font: 16px / 20px "Kadwa", Helvetica, Arial, Verdana, sans-serif;
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  .dropdown-item:hover {
    background: #375896;
    color: white;
  }
  .dropdown-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

let DropdownItem = ({link}) => (
  <Link to={link.url} className="dropdown-item">
    {link.title}
  </Link>
)

const SubMenu = ({link}) => (
  <DropdownSubmenu>
    <Link to={link.url} className="dropdown-item d-flex align-items-center">
      {link.title}
      <FaCaretRight className="ml-auto pl-2"/>
    </Link>
    <ul>
      {link.wordpress_children.map((child, i) =>
        <DropdownItem link={child} key={i}/>
      )}
    </ul>
  </DropdownSubmenu>
)

const MenuItem = ({link, index}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const open = () => {
    setDropdownOpen(true)
  }
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const close = () => {
    setDropdownOpen(false);
  }

  return (
    <Dropdown
      toggle={toggle}
      isOpen={dropdownOpen}
      onMouseOver={open}
      onFocus={open}
      onMouseLeave={close}
      onBlur={close}
      style={{display: 'inline-block'}}
      className="ml-2"
    >
      <DropdownToggle tag={Link} to={link.url} className={'dropdown-toggle btn btn-lg ' + (index === 0 ? 'btn-primary' : 'btn-secondary lighter')}>
        {link.title}
      </DropdownToggle>
      <StyledDropdownMenu right className="m-0">
        {link.wordpress_children.map((child, i) => {
          let Component = (child.wordpress_children === null ? DropdownItem : SubMenu)
          return <Component link={child} key={i}/>
        })}
      </StyledDropdownMenu>
    </Dropdown>
  )
}

const Header = ({menuItems, onClick}) => (
  <StyledSticky enabled={true} top={0} innerClass="header" activeClass="sticky" innerZ={999}>
    <Container className="py-3">
      <div className="d-flex align-items-center">
        <Link to='/'>
          <LogoHorizontal className="d-lg-none logo logo-horizontal"/>
          <Logo className="d-none d-lg-block logo" style={{transitionDuration: '0.5s'}}/>
        </Link>
        <div className="btn btn-outline-secondary d-lg-none ml-auto" onClick={onClick}>
          <FaBars size={28}/>
        </div>
        <div className="ml-auto d-none d-lg-block">
          {menuItems.map((link, i) => (
            <MenuItem
              link={link}
              index={i}
              key={i}
            />
          ))}
        </div>
      </div>
    </Container>
  </StyledSticky>
)

export default withMenu(Header)
