let website = {
  name: 'Techs On The Move',
  phone_numbers: [
    "+61 437 041 154", "+61 2 9552 2750"
  ],
  address: {
    street: "401/56 Bowman Street",
    city: "Pyrmont",
    state: "NSW",
    postcode: 2009,
    country: 'Australia'
  }
}

export default website
