import React from "react"
import styled from 'styled-components'
import Link from './link'

let StyledIcon = styled.div`
  display: flex;
  padding: 0.8rem;
  color: white;
  transition-duration: 0.25s;
  &:hover {
    background: #e2e2e2 !important;
    color: black;
  }
`

export default ({icon, iconStyle = {}, ...props}) => (
  <Link to={icon.link} {...props} style={{display: 'inline-block'}}>
    <StyledIcon style={{background: icon.background, ...iconStyle}}>
      <icon.Icon size="20"/>
    </StyledIcon>
  </Link>
)
