import React, {useRef} from "react"

import TopBanner from "./topBanner"
import Header from "./header"
import Footer from "./footer"
import SubFooter from "./subFooter"
import "../scss/styles.scss"
import SideMenu from './sideMenu'
import {ThemeProvider} from 'styled-components'
import SEO from './seo';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import he from 'he'
import GoogleWidget from './googleWidget'

let theme = {
  primary: '#375893',
  breakpoints: {
    sm: `(min-width: 576px)`,
    smDown: `(max-width: 576px)`,
    md: `(min-width: 768px)`,
    lg: `(min-width: 992px)`,
    xl: `(min-width: 1200px)`
  },
}

function strip(string) {
  return he.decode(string)
}

export default ({children, title, description}) => {
  const sideMenu = useRef();
  return (
    <ThemeProvider theme={theme}>
      <div style={{position: 'relative'}}>
        <SEO title={title ? strip(title) : "Techs on the move"} description={description}/>
        <TopBanner/>
        <SideMenu ref={sideMenu}/>
        <Header onClick={() => sideMenu.current.toggle()}/>
        <link rel="stylesheet" id="dt-web-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto:400,500,600,700|Roboto+Condensed:400,600,700|Kadwa:400,600,700" as="style" onload="this.onload=null;this.rel='stylesheet'" type="text/css" media="all"/>
        <div>
          <main>{children}</main>
          <Footer/>
          <SubFooter/>
        </div>
        <GoogleWidget/>
      </div>
    </ThemeProvider>
  )
}
