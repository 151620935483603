import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

export default Wrapped => ({
  ...options
}) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWordpressWpApiMenusMenusItems(filter: {name: {eq: "New Main Menu"}}) {
        group(field: id) {
          nodes {
            name
            items {
              title
              url
              wordpress_parent
              wordpress_children {
                title
                url
                wordpress_parent
                wordpress_children {
                  title
                  url
                  wordpress_parent
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapped {...options} menuItems={data.allWordpressWpApiMenusMenusItems.group[0].nodes[0].items}/>
  )
}
