import React from "react"
import SideMenuItem from './sideMenuItem'
import styled from 'styled-components'
import withMenu from './withMenu'

let MenuInner = styled.div`
  padding: 1rem;
  ul {
    -webkit-padding-start: 0;
    padding: 0;
  }
  ul > li {
    border-bottom: 1px solid #89969E;
    font-size: 1.1rem;
    font-weight: 600;
  }
`

const SideMenuInner = ({menuItems}) => (
  <MenuInner>
    <ul>
      {menuItems.map((item, i) => (
        <SideMenuItem item={item} key={i}/>
      ))}
    </ul>
  </MenuInner>
)

export default withMenu(SideMenuInner)
