import React, { useState } from 'react';
import  { Collapse, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import Link from './link';
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'

let StyledNavItem = styled(props => <NavItem {...props}/>)`
  list-style: none;
  color: #89969E;
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    background: grey;
    cursor: pointer;
    a.nav-link {
      color: white;
    }
    svg {
      color: white;
    }
  }
  svg {
    color: black;
    transition-duration: 0.2s;
  }
  &.menu-open {
    svg {
      transform: rotate(45deg);
    }
  }
`

let StyledCollapse = styled(props => <Collapse {...props}/>)`
  padding-left: 1rem;
`

let DropdownMenu = ({collapsed, items}) => (
  <StyledCollapse isOpen={!collapsed} navbar className={classNames({'mb-1': !collapsed})}>
    {items.map((item, i) => (
      <MenuItem item={item} key={i}/>
    ))}
  </StyledCollapse>
)

let Expand = ({onClick}) => (
  <div style={{flex: '1', display: 'flex', alignSelf: 'stretch', alignItems: 'center'}} onClick={onClick}>
    <FaPlus
      className="mr-2"
      style={{marginLeft: 'auto'}}
    />
  </div>
)

const MenuItem = ({item}) => {
  const [collapsed, setCollapsed] = useState(true)
  const toggleNavbar = (e) => {
    e.preventDefault()
    setCollapsed(!collapsed)
  }
  const items = item.wordpress_children
  return (
    <>
      <StyledNavItem className={classNames({'menu-open': !collapsed})}>
        <NavLink
          tag={Link}
          to={item.url}
          style={items ? {} : {width: '100%'}}
        >
          {item.title}
        </NavLink>
        {items ? <Expand onClick={toggleNavbar}/> : ''}
      </StyledNavItem>
      {items ? <DropdownMenu collapsed={collapsed} items={items}/> : ''}
    </>
  );
}

export default MenuItem
