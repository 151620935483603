import React from 'react'
import {Container} from 'reactstrap'
import styled from 'styled-components'
import icons from '../data/socialLinks'
import SocialIcon from './socialIcon'

let Banner = styled.div`
  background: #e2e2e2;
`

export default () => (
  <Banner className="d-none d-lg-block">
    <Container className='d-flex'>
      <div className="ml-auto">
        {icons.map((icon, i) => (
          <SocialIcon icon={icon} key={i}/>
        ))}
      </div>
    </Container>
  </Banner>
)
