import React from 'react'
import SocialIcon from './socialIcon'
import socialIcons from '../data/socialLinks'

export default () => (
  <>
    {socialIcons.map((icon, i) => (
      <SocialIcon
        icon={icon}
        key={i}
        className="mr-2"
        iconStyle={{
          borderRadius: '50%',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }}
      />
    ))}
  </>
)
